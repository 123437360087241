import React, { useState } from 'react';
import { useCart } from '../contexts/cartContext';
import { useNavigate } from 'react-router-dom';
import './CartDrawer.css';

const CartDrawer = () => {
  const { cart, removeFromCart, updateQuantity, getTotalPrice } = useCart();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleCart = () => setIsOpen(!isOpen);

  const handleCheckout = () => {
    setIsOpen(false);
    navigate('/checkout');
  };

  const handleContinueShopping = () => {
    setIsOpen(false);
    navigate('/products');
  };

  return (
    <>
      {!isOpen && (
        <button className="cart-button" onClick={toggleCart}>
          🛒 Cart ({cart.length})
        </button>
      )}

      <div className={`cart-drawer ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Review Your Cart</h2>
          <button className="close-button" onClick={toggleCart}>×</button>
        </div>

        <div className="cart-items">
          {cart.length > 0 ? (
            cart.map((item, index) => (
              <div key={index} className="cart-item">
                <img src={item.imageUrls[0]} alt={item.name} className="item-image" />
                <div className="item-details">
                  <h3>{item.name}</h3>
                  <p className="item-price">{item.price.toFixed(2)} EGP</p>
                  <div className="quantity-control">
                    <button onClick={() => updateQuantity(item, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => updateQuantity(item, item.quantity + 1)}>+</button>
                  </div>
                </div>
                <button className="remove-item" onClick={() => removeFromCart(item)}>×</button>
              </div>
            ))
          ) : (
            <p>Your cart is empty.</p>
          )}
        </div>

        <div className="cart-footer">
          
          <div className="subtotal">
            <span>Subtotal</span>
            <span>{getTotalPrice().toFixed(2)} EGP</span>
          </div>
          <p className="shipping-note">Shipping & taxes may be re-calculated at checkout</p>
          <button className="checkout-button" onClick={handleCheckout}>
            Checkout {getTotalPrice().toFixed(2)} EGP
          </button>
          <button className="continue-shopping" onClick={handleContinueShopping}>
            Continue Shopping
          </button>
        </div>
      </div>

      {isOpen && <div className="overlay" onClick={toggleCart}></div>}
    </>
  );
};

export default CartDrawer;