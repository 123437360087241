import React, { useState } from 'react';
import axios from 'axios';
import { useCart } from '../contexts/cartContext';
import { Plus, Minus, X } from 'lucide-react';
import './Checkout.css';

const API_BASE_URL = 'https://kalbwotta-backend-c69d103854a0.herokuapp.com';

export default function Checkout() {
  const { cart, getTotalPrice, updateQuantity, removeFromCart, clearCart } = useCart();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '', // Changed from 'phone' to 'phoneNumber' to match backend
    address: '',
    city: '',
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    const orderDetails = {
      products: cart,
      totalPrice: getTotalPrice(),
      shippingAddress: `${formData.address}, ${formData.city}`,
      ...formData,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/orders/create-order`, orderDetails);
      if (response.status === 201) {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: '',
          city: '',
        });
        clearCart();
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error placing order:', error);
      alert('Error placing order. Please try again.');
    }
  };

  const handleQuantityChange = (item, change) => {
    const newQuantity = item.quantity + change;
    if (newQuantity > 0) {
      updateQuantity(item, newQuantity);
    } else {
      removeFromCart(item);
    }
  };

  return (
    <div className="checkout-container">
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="popup-close-btn" onClick={() => setShowPopup(false)}>
              <X size={24} />
            </button>
            <p>Order placed successfully! 🎉</p>
            <p>An email confirmation has been sent to you.</p>
          </div>
        </div>
      )}

      <div className="checkout-form">
        <h1>Checkout</h1>
        <form onSubmit={handlePlaceOrder}>
          <div className="form-section">
            <h2>Contact Information</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Shipping Address</h2>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="btn-place-order">
            Place Order
          </button>
        </form>
      </div>

      <div className="checkout-summary">
        <h2>Order Summary</h2>
        <div className="cart-items">
          {cart.map((item) => (
            <div key={item._id} className="cart-item">
              <img src={item.imageUrls[0]} alt={item.name} className="cart-item-image" />
              <div className="cart-item-details">
                <span className="cart-item-name">{item.name}</span>
                <span className="cart-item-price">LE{item.price.toFixed(2)}</span>
                <div className="cart-item-quantity">
                  <button onClick={() => handleQuantityChange(item, -1)} className="quantity-btn">
                    <Minus size={16} />
                  </button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleQuantityChange(item, 1)} className="quantity-btn">
                    <Plus size={16} />
                  </button>
                </div>
              </div>
              <button onClick={() => removeFromCart(item)} className="remove-item-btn">
                <X size={16} />
              </button>
            </div>
          ))}
        </div>
        <div className="cart-total">
          <span>Total</span>
          <span>LE{getTotalPrice().toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
}