import { Link } from "react-router-dom";
import { useState } from "react";
import { Slider } from "@mui/material"; // Import Slider from Material UI
import "./FilterSideBar.css"; // Ensure styles are applied

const FilterSidebar = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 500]); // Default price range

  const toggleCategory = (category) => {
    setActiveCategory((prev) => (prev === category ? null : category));
    setActiveSubcategory(null); // Reset subcategory when switching categories
  };

  const toggleSubcategory = (subcategory) => {
    setActiveSubcategory((prev) => (prev === subcategory ? null : subcategory));
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <div className="filter-sidebar">
      <h2 className="sidebar-title">Main Menu</h2>

      {/* Price Range Slider */}


      <ul className="filter-menu">
        {/* Dog Category */}
        <li className="filter-category">
          <div className="category-header" onClick={() => toggleCategory("Dog")}>
            <Link to="/products?category=Dog" className="category-link">Dogs</Link>
            <span className={`toggle-icon ${activeCategory === "Dog" ? "open" : ""}`}>▼</span>
          </div>
          {activeCategory === "Dog" && (
            <ul className="subcategory-list">
              <li>
                <div onClick={() => toggleSubcategory("Dog Food")}>
                  <Link to="/products?category=Dog&subcategory=Dog Food" className="subcategory-link">Dog Food</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Dog Food" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Dog Food" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Dry Food" className="subsubcategory-link">Dry Dog Food</Link></li>
                    <li><Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Wet Food" className="subsubcategory-link">Wet Dog Food</Link></li>
                    <li><Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Treats" className="subsubcategory-link">Treats</Link></li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={() => toggleSubcategory("Health & Happiness")}>
                  <Link to="/products?category=Dog&subcategory=Health & Happiness" className="subcategory-link">Health & Happiness</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Health & Happiness" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Health & Happiness" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Dog&subcategory=Health & Happiness&subsubcategory=Supplements" className="subsubcategory-link">Supplements</Link></li>
                    <li><Link to="/products?category=Dog&subcategory=Health & Happiness&subsubcategory=Training Tools" className="subsubcategory-link">Training Tools</Link></li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={() => toggleSubcategory("Dog Accessories")}>
                  <Link to="/products?category=Dog&subcategory=Dog Accessories" className="subcategory-link">Dog Accessories</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Dog Accessories" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Dog Accessories" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Dog&subcategory=Dog Accessories&subsubcategory=Toys" className="subsubcategory-link">Toys</Link></li>

                  </ul>
                )}
              </li>
            </ul>
          )}
        </li>

        {/* Cats Category */}
        <li className="filter-category">
          <div className="category-header" onClick={() => toggleCategory("Cat")}>
            <Link to="/products?category=Cat" className="category-link">Cats</Link>
            <span className={`toggle-icon ${activeCategory === "Cat" ? "open" : ""}`}>▼</span>
          </div>
          {activeCategory === "Cat" && (
            <ul className="subcategory-list">
              <li>
                <div onClick={() => toggleSubcategory("Cat Food")}>
                  <Link to="/products?category=Cat&subcategory=Cat Food" className="subcategory-link">Cat Food</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Cat Food" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Cat Food" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Dry Food" className="subsubcategory-link">Dry Cat Food</Link></li>
                    <li><Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Wet Food" className="subsubcategory-link">Wet Cat Food</Link></li>
                    <li><Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Treats" className="subsubcategory-link">Treats</Link></li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={() => toggleSubcategory("Health & Cleanliness")}>
                  <Link to="/products?category=Cat&subcategory=Health & Cleanliness" className="subcategory-link">Health & Cleanliness</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Health & Cleanliness" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Health & Cleanliness" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Cat&subcategory=Health & Cleanliness&subsubcategory=Litter" className="subsubcategory-link">Litter</Link></li>
                    <li><Link to="/products?category=Cat&subcategory=Health & Cleanliness&subsubcategory=Supplements" className="subsubcategory-link">Supplements</Link></li>
                    <li><Link to="/products?category=Cat&subcategory=Health & Cleanliness&subsubcategory=Grooming Tools" className="subsubcategory-link">Grooming Tools</Link></li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={() => toggleSubcategory("Cat Accessories")}>
                  <Link to="/products?category=Cats&subcategory=Cat Accessories" className="subcategory-link">Cat Accessories</Link>
                  <span className={`toggle-icon ${activeSubcategory === "Cat Accessories" ? "open" : ""}`}>▼</span>
                </div>
                {activeSubcategory === "Cat Accessories" && (
                  <ul className="subsubcategory-list">
                    <li><Link to="/products?category=Cat&subcategory=Cat Accessories&subsubcategory=Litter Box" className="subsubcategory-link">Litter Box</Link></li>
                    <li><Link to="/products?category=Cat&subcategory=Cat Accessories&subsubcategory=Toys" className="subsubcategory-link">Toys</Link></li>
                  </ul>
                )}
              </li>
            </ul>
          )}
        </li>
      </ul>

      <div className="price-filter">
        <h3>Price Range</h3>
        <Slider
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          min={0}
          max={1000}
          sx={{
            color: "#90c349", // Custom slider color
          }}
        />
        <div className="price-display">
          <span>{priceRange[0]} LE</span> - <span>{priceRange[1]} LE</span>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
