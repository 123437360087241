import React from "react";
import "../pages/adminDashBoard.css";
import { useNavigate } from "react-router-dom";

function AdminDashBoard() {
  const navigate = useNavigate();

  const handleGoToProducts = () => {
    navigate("/admin-products");
  };

  const handleGoToOrders = () => {
    navigate("/orders");
  };
  return (
    <div className="adminDashContainer">
      <button
        to="/admin-products"
        className="productAdmin"
        onClick={() => handleGoToProducts()}
      >
        products
      </button>
      <button className="userAdmin">user</button>
      <button className="orderAdmin" onClick={() => handleGoToOrders()}>orders</button>
    </div>
  );
}

export default AdminDashBoard;
