import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Orders.css';

const API_BASE_URL = 'https://kalbwotta-backend-c69d103854a0.herokuapp.com';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/orders/get-all-orders`);
        setOrders(response.data.orders);
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data.error);
        } else {
          setError('An error occurred while fetching the orders');
        }
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="orders-container">
      <h1>Orders</h1>
      {error && <p className="error-message">{error}</p>}
      {orders.length > 0 ? (
        <ul className="orders-list">
          {orders.map(order => (
            <li key={order._id} className="order-item">
              <h2>Order ID: {order._id}</h2>
              <p><strong>User:</strong> {order.user ? order.user : 'Guest'}</p>
              <p><strong>Total Price:</strong> ${order.totalPrice.toFixed(2)}</p>
              <p><strong>Shipping Address:</strong> {order.shippingAddress}</p>
              <p><strong>Order Status:</strong> {order.orderStatus}</p>
              <p><strong>Payment Status:</strong> {order.paymentStatus}</p>
              <h3>Products:</h3>
              <ul>
                {order.products.map(p => (
                  p.product ? (
                    <li key={p.product._id}>
                      <p><strong>Product ID:</strong> {p.product._id}</p>
                      <p><strong>Product Name:</strong> {p.product.name}</p>
                      <p><strong>Quantity:</strong> {p.quantity}</p>
                      <p><strong>Price:</strong> ${p.price.toFixed(2)}</p>
                    </li>
                  ) : (
                    <li key={Math.random()}>
                      <p><strong>Product:</strong> This product has been removed</p>
                      <p><strong>Quantity:</strong> {p.quantity}</p>
                      <p><strong>Price:</strong> ${p.price.toFixed(2)}</p>
                    </li>
                  )
                ))}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
}

export default Orders;
