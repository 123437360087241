import React from "react";
import "./AdminProducts.css";
import { useNavigate } from "react-router-dom";

function AdminProducts() {
  const navigate = useNavigate();
  const handleGoToAddProducts = () => {
    navigate("/add-product");
  };
  const handleGoToDeleteProducts = () => {
    navigate("/delete-product");
  };
  const handleGoToUpdateProducts = () => {
    navigate("/update-product/:id");
  };
  return (
    <div className="adminProductsContainer">
      <button
        className="add_productDash"
        onClick={() => handleGoToAddProducts()}
      >
        Add Products
      </button>

      <button
        className="delete_productDash"
        onClick={() => handleGoToDeleteProducts()}
      >
        Delete Products
      </button>

      <button
        className="update_productDash"
        onClick={() => handleGoToUpdateProducts()}
      >
        Update Products
      </button>
    </div>
  );
}

export default AdminProducts;
