import React, { useEffect, useState } from 'react';
import { Phone, MessageCircle, Dog, Cat, PawPrint, Heart, ChevronRight, Pill } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Home.css';
import { useCart } from '../contexts/cartContext';
import PetSupport from '../images/pet-support.webp';
import HappyPets from '../images/happypets.jpg';
import ClinicImage from '../images/clinic-image.jpg';

const API_BASE_URL = "https://kalbwotta-backend-c69d103854a0.herokuapp.com";

const categories = [
  {
    name: "Dogs",
    icon: <Dog />,
    color: "#2c94d5",
    link: "/products?category=Dog",
  },
  {
    name: "Cats",
    icon: <Cat />,
    color: "#90c349",
    link: "/products?category=Cat",
  },
  {
    name: "Pharmacy",
    icon: <Pill />,
    color: "#ff6b6b",
    link: "/products?category=Pharmacy",
  },
];

export default function Home() {
  const [popularProducts, setPopularProducts] = useState([]);
  const [dogProducts, setDogProducts] = useState([]);
  const [catProducts, setCatProducts] = useState([]);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products/get-popular-products`);
        setPopularProducts(response.data);
      } catch (error) {
        console.error("Error fetching popular products:", error);
      }
    };

    const fetchDogProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products/filter-products?category=Dog&limit=10`);
        setDogProducts(response.data);
      } catch (error) {
        console.error("Error fetching dog products:", error);
      }
    };

    const fetchCatProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products/filter-products?category=Cat&limit=10`);
        setCatProducts(response.data);
      } catch (error) {
        console.error("Error fetching cat products:", error);
      }
    };

    fetchPopularProducts();
    fetchDogProducts();
    fetchCatProducts();
  }, []);

  const handleProductClick = (productId) => navigate(`/products/${productId}`);   

  const renderProductScroller = (products, title, viewAllLink) => (
    <section className="home-product-section">
      <div className="home-section-header">
        <h2>{title}</h2>
        <Link to={viewAllLink} className="home-view-all-link">
          View All <ChevronRight size={20} />
        </Link>
      </div>
      <div className="home-product-scroller">
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product._id}
              className="kw-product-card"
              onClick={() => handleProductClick(product._id)}
            >
              <div className="kw-product-image-wrapper">
                <div className="kw-product-image-container">
                  <img
                    src={product.imageUrls[0]}
                    alt={product.name}
                    className="kw-product-image"
                  />
                </div>
              </div>
              <h4 className="kw-product-name">{product.name}</h4>
              <p className="kw-product-price">{product.price.toFixed(2)} LE</p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addToCart(product);
                }}
                className="kw-buy-button"
              >
                Add to Cart
              </button>
            </div>
          ))
        ) : (
          <p>No products found.</p>
        )}
      </div>
    </section>
  );

  return (
    <div className="container">
      <main>
        <section className="home-hero">
          <div className="home-hero-content">
            <h2>Welcome to kalbWotta Pet Store</h2>
            <p>Your one-stop shop for all pet needs</p>
            <button className="cta-button" onClick={() => navigate('/products?category=Dog')}>Shop Now</button>
          </div>
          <div className="home-hero-image">
            <img src={HappyPets} alt="Happy pets" />
          </div>
        </section>

        <section className="home-categories">
          <h2>Shop by Category</h2>
          <div className="home-category-grid">
            {categories.map((category, index) => (
              <Link
                key={index}
                to={category.link}
                className="home-category-card"
                style={{ backgroundColor: category.color }}
              >
                {category.icon}
                <h3>{category.name}</h3>
              </Link>
            ))}
          </div>
        </section>

        {renderProductScroller(popularProducts, "Popular Products", "/products")}
        {renderProductScroller(dogProducts, "Dog Products", "/products?category=Dog")}
        {renderProductScroller(catProducts, "Cat Products", "/products?category=Cat")}

        <section className="home-clinic">
          <div className="home-clinic-content">
            <h2>Visit Our Veterinary Clinic</h2>
            <p>Expert care for your beloved pets. Our experienced veterinarians are here to help keep your furry friends healthy and happy.</p>
            <a href="https://www.kalbwotta.clinic" target="_blank" rel="noopener noreferrer" className="cta-button">Visit Clinic Website</a>
          </div>
          <div className="home-clinic-image">
            <img src={ClinicImage} alt="KalbWotta Veterinary Clinic" />
          </div>
        </section>

        <section className="home-support">
          <div className="home-support-content">
            <h2>We're Here for You and Your Pets</h2>
            <p>
              Our pet experts are available 7 days a week to answer your
              questions and provide tailored advice for your furry friends.
            </p>
            <div className="home-support-features">
              <div className="home-support-feature">
                <PawPrint />
                <span>Pet Care Tips</span>
              </div>
              <div className="home-support-feature">
                <Heart />
                <span>Product Recommendations</span>
              </div>
            </div>
            <div className="home-support-options">

              <a 
                href="https://wa.me/201030503023" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="home-support-button"
              >
                <MessageCircle />
                Chat with Us
              </a>
            </div>
          </div>
          <div className="home-support-image">
            <img src={PetSupport} alt="Pet support" />
          </div>
        </section>
      </main>
    </div>
  );
}