import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header';
import Footer from './components/Footer';
import Signin from './pages/Signin';
import SignUp from './pages/SignUp';
import AdminDashboard from './pages/AdminDashBoard';
import AdminProducts from './pages/AdminProducts';
import Home from './pages/Home';
import UserProfile from './pages/userProfile';
import AddProduct from './pages/AddProduct';
import Checkout from './pages/Checkout';
import DeleteProduct from './pages/deleteProduct';
import ProductDetailPage from './pages/ProductDetailPage';
import ProductList from './pages/ProductList';
import Orders from './pages/Orders';
import './global.css';
import UpdateProductPage from './pages/UpdateProduct';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './contexts/cartContext';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from local storage when the app loads
    const savedUser = localStorage.getItem('user');
    console.log('Saved user:', savedUser); // Log saved user data
    if (savedUser && savedUser !== 'undefined') {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  return (
    <Router>
      <div>
        <CartProvider>
          <Header user={user} setUser={setUser} /> {/* Pass setUser to Header */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<Signin setUser={setUser} />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/admin-products" element={<AdminProducts />} />
            <Route path="/home" element={<Home />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/user-profile" element={<UserProfile user={user} setUser={setUser} />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/products/:id" element={<ProductDetailPage />} />
            <Route path="/delete-product" element={<DeleteProduct />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/update-product/:id" element={<UpdateProductPage />} />
          </Routes>
          <Footer />
        </CartProvider>
      </div>
    </Router>
  );
}

export default App;
