import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebookF, 
  faInstagram, 
  faWhatsapp 
} from '@fortawesome/free-brands-svg-icons';
import { 
  faEnvelope, 
  faPhone, 
  faMapMarkerAlt 
} from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        <div className="footer-section about">
          <h3>About Kalbwotta</h3>
          <p>
            Kalbwotta is your trusted partner for all pet needs. We provide high-quality products
            and expert advice to ensure the health and happiness of your furry friends.
          </p>
          <div className="social-icons">
            <a href="https://www.facebook.com/kalbwotta" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com/kalbwotta" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>

        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/products?category=Dog">Dogs</a></li>
            <li><a href="/products?category=Cat">Cats</a></li>
            <li><a href="/checkout">Checkout</a></li>

          </ul>
        </div>

        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p>
            <FontAwesomeIcon icon={faPhone} /> <a href="tel:+201030503023">+201030503023</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@kalbwotta.com">info@kalbwotta.clinic</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Grand Mall Shopping Center, Al Shorouk, Cairo Governorate, Cairo, Egypt
          </p>
        </div>

        <div className="footer-section newsletter">
          <h3>Subscribe to Our Newsletter</h3>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Kalbwotta. All rights reserved.</p>
      </div>

      <div className="whatsapp-float">
        <a href="https://wa.me/201030503023" target="_blank" rel="noopener noreferrer" aria-label="Chat on WhatsApp">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;