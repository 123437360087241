import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./userProfile.css";
import { Link, useNavigate } from "react-router-dom";

const UserProfile = ({ user, setUser }) => {
  const [editingUsername, setEditingUsername] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [editedUsername, setEditedUsername] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedAddress, setEditedAddress] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fetchToken = async () => {
    try {
      const response = await axios.get(
        "https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/users/get-token"
      );
      const authToken = response.data.token;
      return authToken;
    } catch (error) {
      console.error("Error getting token in fetchToken:", error);
    }
  };

  const handleUsernameInputChange = (e) => {
    setEditedUsername(e.target.value);
  };

  const handleEmailInputChange = (e) => {
    setEditedEmail(e.target.value);
  };

  const handleAddressInputChange = (e) => {
    setEditedAddress(e.target.value);
  };

  const handleFormSubmit = async (e, field) => {
    e.preventDefault();
    const authToken = await fetchToken();
    if (!authToken) {
      console.error("Token not available");
      return;
    }
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (field === "username") {
        await axios.put(
          "https://kalbwotta-1f96e9d6ef90.herokuapp.com/api/users/update-username",
          { username: editedUsername },
          axiosConfig
        );
        setUser({ ...user, username: editedUsername });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, username: editedUsername })
        );
        setEditingUsername(false);
      } else if (field === "email") {
        await axios.put(
          "https://kalbwotta-1f96e9d6ef90.herokuapp.com/api/users/update-email",
          { email: editedEmail },
          axiosConfig
        );
        setUser({ ...user, email: editedEmail });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, email: editedEmail })
        );
        setEditingEmail(false);
      } else if (field === "address") {
        await axios.put(
          "https://kalbwotta-1f96e9d6ef90.herokuapp.com/api/users/update-address",
          { address: editedAddress },
          axiosConfig
        );
        setUser({ ...user, address: editedAddress });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, address: editedAddress })
        );
        setEditingAddress(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      if (error.response && error.response.data) {
        console.log("Response data:", error.response.data);
      } else {
        console.log("Error response:", error.response);
      }
    }
  };

  const handleCancelClick = (field) => {
    if (field === "username") {
      setEditedUsername(user.username);
      setEditingUsername(false);
    } else if (field === "email") {
      setEditedEmail(user.email);
      setEditingEmail(false);
    } else if (field === "address") {
      setEditedAddress(user.address);
      setEditingAddress(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      const { data } = await axios.post(
        "http://localhost:3000/api/users/reset-password",
        {
          // Updated to localhost
          emailOrUsername: user.email,
          oldPassword,
          newPassword,
        }
      );

      console.log(data);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setErrorMessage("");
    } catch (error) {
      console.error("Error resetting password:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("An error occurred while resetting password");
      }
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null); // Set user state to null upon logout
    navigate("/sign-in");
  };

  return (
    <div className="container">
      <div className="user-profile">
        <h1 className="title">User Profile</h1>
        {editingUsername ? (
          <form onSubmit={(e) => handleFormSubmit(e, "username")}>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                name="username"
                id="username"
                value={editedUsername}
                onChange={handleUsernameInputChange}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save Username
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handleCancelClick("username")}
            >
              Cancel
            </button>
          </form>
        ) : (
          <>
            <p>
              <strong>Username:</strong> {user.username}
            </p>
            <button
              onClick={() => setEditingUsername(true)}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faPen} /> Edit Username
            </button>
          </>
        )}
        {editingEmail ? (
          <form onSubmit={(e) => handleFormSubmit(e, "email")}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                id="email"
                value={editedEmail}
                onChange={handleEmailInputChange}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save Email
            </button>
            <button
              type="button"
              onClick={() => handleCancelClick("email")}
              className="btn btn-primary"
            >
              Cancel
            </button>
          </form>
        ) : (
          <>
            <p className="inf">
              <strong>Email:</strong> {user.email}
            </p>
            <button
              onClick={() => setEditingEmail(true)}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faPen} /> Edit Email
            </button>
          </>
        )}
        {editingAddress ? (
          <form onSubmit={(e) => handleFormSubmit(e, "address")}>
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <textarea
                name="address"
                id="address"
                value={editedAddress}
                onChange={handleAddressInputChange}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save Address
            </button>
            <button
              type="button"
              onClick={() => handleCancelClick("address")}
              className="btn btn-primary"
            >
              Cancel
            </button>
          </form>
        ) : (
          <>
            <p>
              <strong>Address:</strong> {user.address}
            </p>
            <button
              onClick={() => setEditingAddress(true)}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faPen} /> Edit Address
            </button>
          </>
        )}

        <div className="password-reset">
          <h2>Reset Password</h2>
          <div className="form-group">
            <label htmlFor="oldPassword">Old Password:</label>
            <input
              type="password"
              name="oldPassword"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="logoutreset">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlePasswordReset}
            >
              Reset Password
            </button>
            <div className="logout">
              <button onClick={handleLogout} className="btn btn-primary">
                Logout
              </button>
            </div>
          </div>
        </div>
        {user.role === "admin" && ( // Conditional rendering for admin button
          <div className="admin-dashboard">
            <Link to="/admin-dashboard">
              <button className="btn btn-primary">Go to Admin Dashboard</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
