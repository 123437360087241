import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddProduct.css';

const API_BASE_URL = 'https://kalbwotta-backend-c69d103854a0.herokuapp.com';

const categoryData = {
  Cat: ["Cat Food", "Health And Cleanliness", "Cat Accessories"],
  Dog: ["Dog Food", "Health And Happiness", "Dog Accessories"],
  Pharmacy: ['Pharmacy'],
};

const subsubcategoryData = {
  "Cat Food": ["Dry Food", "Wet Food","Treats"],
  "Health And Cleanliness": ["Grooming Tools", "Litter", "Supplements"],
  "Cat Accessories": ["Litter Box", "Toys"],
  "Dog Accessories": ["Toys"],
  "Dog Food": ["Dry Food", "Wet Food", "Treats"],
  "Health And Hapiness": ["Vitamins", "Training Tools", "Supplements"],
};

function AddProduct() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subsubcategory, setSubsubcategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [subsubcategories, setSubsubcategories] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isPopular, setIsPopular] = useState(false); // State for checkbox

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setSubcategories(categoryData[selectedCategory] || []);
    setSubcategory("");
    setSubsubcategories([]);
    setSubsubcategory("");
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategory = e.target.value;
    setSubcategory(selectedSubcategory);
    setSubsubcategories(subsubcategoryData[selectedSubcategory] || []);
    setSubsubcategory("");
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/products/upload-image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setImageUrl(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const productData = {
        name,
        description,
        price,
        category,
        subcategory,
        subsubcategory,
        imageUrls: [imageUrl],
        isPopular, // Include isPopular in the product data
      };
      await axios.post(`${API_BASE_URL}/api/products/add-product`, productData);
      alert("Product added successfully");
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="add-product-body">
      <form onSubmit={handleSubmit} className="add-product-form">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          required
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Price"
          required
        />

        {/* Category Dropdown */}
        <select value={category} onChange={handleCategoryChange} required>
          <option value="">Select Category</option>
          {Object.keys(categoryData).map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>

        {/* Subcategory Dropdown */}
        <select value={subcategory} onChange={handleSubcategoryChange} disabled={!category} required>
          <option value="">Select Subcategory</option>
          {subcategories.map((subcat) => (
            <option key={subcat} value={subcat}>
              {subcat}
            </option>
          ))}
        </select>

        {/* Sub-subcategory Dropdown */}
        <select
          value={subsubcategory}
          onChange={(e) => setSubsubcategory(e.target.value)}
          disabled={!subcategory}
        >
          <option value="">Select Sub-subcategory</option>
          {subsubcategories.map((subsub) => (
            <option key={subsub} value={subsub}>
              {subsub}
            </option>
          ))}
        </select>

        <input type="file" onChange={handleImageUpload} />
        {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ width: '200px', height: 'auto' }} />}

        {/* Checkbox for "isPopular" */}
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={isPopular}
              onChange={(e) => setIsPopular(e.target.checked)}
            />
            Mark as Popular
          </label>
        </div>

        <button type="submit">Add Product</button>
      </form>
    </div>
  );
}

export default AddProduct;
