import React, { useState } from 'react';
import './SignUp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    address: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/users/register', formData);

      console.log(response.data); // Handle successful response
      setFormData({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        address: ''
      });
      navigate('/sign-in'); // Redirect to the Sign In page
    } catch (error) {
      console.error('Error:', error); // Handle error
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
        <h2>Create an Account</h2>
        <div className="fullname">
          <div className="firstname">
            <label htmlFor="firstName">First Name</label>
            <input 
              type="text" 
              id="firstName" 
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required 
            />
          </div>
          <div className="lastname">
            <label htmlFor="lastName">Last Name</label>
            <input 
              type="text" 
              id="lastName" 
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required 
            />
          </div>
        </div>
        <label htmlFor="username">Username</label>
        <input 
          type="text" 
          id="username" 
          name="username"
          value={formData.username}
          onChange={handleChange}
          required 
        />
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email"
          value={formData.email}
          onChange={handleChange}
          required 
        />
        <label htmlFor="password">Password</label>
        <input 
          type="password" 
          id="password" 
          name="password"
          value={formData.password}
          onChange={handleChange}
          required 
        />
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input 
          type="password" 
          id="confirmPassword" 
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required 
        />
        <label htmlFor="phoneNumber">Phone Number</label>
        <input 
          type="text" 
          id="phoneNumber" 
          name="phoneNumber"  
          value={formData.phoneNumber}
          onChange={handleChange}
          required 
        />
        <label htmlFor="address">Address</label>
        <input 
          type="text" 
          id="address" 
          name="address"  
          value={formData.address}
          onChange={handleChange}
          required 
        />
        <input type="submit" value="Sign Up" className="signup-submit" />
      </form>
    </div>
  );
};

export default SignUp;
