import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Trash2, Search, X } from 'lucide-react';
import './DeleteProduct.css';

const API_BASE_URL = 'https://kalbwotta-backend-c69d103854a0.herokuapp.com';

function DeleteProduct() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      const fetchProducts = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${API_BASE_URL}/api/products/search-products/${searchQuery}`);
          setSearchResults(response.data);
          setShowSearchResults(true);
          setError('');
        } catch (error) {
          if (error.response && error.response.data) {
            setError(error.response.data.error);
          } else {
            setError('An error occurred while searching for products');
          }
          setSearchResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      const debounceTimeout = setTimeout(() => {
        fetchProducts();
      }, 300); // Debounce by 300ms

      return () => clearTimeout(debounceTimeout);
    } else {
      setSearchResults([]);
      setShowSearchResults(false);
    }
  }, [searchQuery]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    setSelectedProduct(null);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setShowSearchResults(false);
    setSearchQuery('');
    setMessage('');
    setError('');
  };

  const handleDelete = async () => {
    if (!selectedProduct) return;
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/products/delete-product/${selectedProduct._id}`);
      setMessage(response.data.message);
      setError('');
      setSelectedProduct(null);
      setSearchResults([]);
      setShowConfirmModal(false);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while deleting the product');
      }
      setMessage('');
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
    setShowSearchResults(false);
  };

  return (
    <div className="kw-delete-product-container">
      <h1 className="kw-delete-product-title">Delete Product</h1>
      <div className="kw-delete-product-search">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder="Search product by name..."
          className="kw-delete-product-search-input"
        />
        {searchQuery && (
          <button onClick={clearSearch} className="kw-delete-product-clear-search">
            <X size={20} />
          </button>
        )}
        <Search className="kw-delete-product-search-icon" size={20} />
      </div>

      {isLoading && <div className="kw-delete-product-loading">Loading...</div>}

      {showSearchResults && searchResults.length > 0 && (
        <div className="kw-delete-product-results">
          <h2 className="kw-delete-product-results-title">Search Results:</h2>
          <ul className="kw-delete-product-list">
            {searchResults.map((product) => (
              <li
                key={product._id}
                onClick={() => handleProductSelect(product)}
                className="kw-delete-product-item"
              >
                {product.imageUrls && product.imageUrls.length > 0 && (
                  <img src={product.imageUrls[0]} alt={product.name} className="kw-delete-product-image" />
                )}
                <div className="kw-delete-product-info">
                  <h3 className="kw-delete-product-name">{product.name}</h3>
                  <p className="kw-delete-product-description">{product.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedProduct && (
        <div className="kw-delete-product-selected">
          <h2 className="kw-delete-product-selected-title">Selected Product:</h2>
          <div className="kw-delete-product-selected-content">
            {selectedProduct.imageUrls && selectedProduct.imageUrls.length > 0 && (
              <img src={selectedProduct.imageUrls[0]} alt={selectedProduct.name} className="kw-delete-product-selected-image" />
            )}
            <div className="kw-delete-product-selected-info">
              <p><strong>Name:</strong> {selectedProduct.name}</p>
              <p><strong>Description:</strong> {selectedProduct.description}</p>
              <p><strong>Price:</strong> LE{selectedProduct.price.toFixed(2)}</p>
              <button onClick={() => setShowConfirmModal(true)} className="kw-delete-product-button">
                <Trash2 size={20} /> Delete Product
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div className="kw-delete-product-modal-overlay">
          <div className="kw-delete-product-modal">
            <h2 className="kw-delete-product-modal-title">Confirm Deletion</h2>
            <p className="kw-delete-product-modal-message">Are you sure you want to delete this product?</p>
            <div className="kw-delete-product-modal-actions">
              <button onClick={handleDelete} className="kw-delete-product-modal-confirm">Yes, Delete</button>
              <button onClick={() => setShowConfirmModal(false)} className="kw-delete-product-modal-cancel">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {message && <p className="kw-delete-product-success">{message}</p>}
      {error && <p className="kw-delete-product-error">{error}</p>}
    </div>
  );
}

export default DeleteProduct;