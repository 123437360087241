import React, { useState } from "react";
import axios from "axios";
import "./UpdateProduct.css";

const UpdateProductPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [message, setMessage] = useState("");
  const [newImage, setNewImage] = useState(null);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/products/search-products/${searchQuery}`
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error searching products:", error);
      setMessage("Failed to search products.");
    }
  };

  const selectProduct = async (productId) => {
    try {
      const response = await axios.get(
        `https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/products/get-product/${productId}`
      );
      setSelectedProduct(response.data);
      setProductDetails(response.data);
      setSearchResults([]);
    } catch (error) {
      console.error("Error fetching product:", error);
      setMessage("Failed to fetch product details.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: fieldValue,
    }));
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    
    // Create the update data object
    const updateData = {
      name: productDetails.name,
      description: productDetails.description,
      price: productDetails.price,
      category: productDetails.category,
      subcategory: productDetails.subcategory,
      subsubcategory: productDetails.subsubcategory,
      imageUrls: productDetails.imageUrls,
      isPopular: productDetails.isPopular
    };

    try {
      // If there's a new image, upload it first
      if (newImage) {
        const imageFormData = new FormData();
        imageFormData.append('image', newImage);
        const imageUploadResponse = await axios.post(
          'https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/products/upload-image',
          imageFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
        updateData.imageUrls = [imageUploadResponse.data.imageUrl];
      }

      // Send the update request with JSON data
      const response = await axios.put(
        `https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/products/update-product/${selectedProduct._id}`,
        updateData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      setMessage(response.data.message);
      
      // Refresh the product details after update
      const updatedProduct = await axios.get(
        `https://kalbwotta-backend-c69d103854a0.herokuapp.com/api/products/get-product/${selectedProduct._id}`
      );
      setSelectedProduct(updatedProduct.data);
      setProductDetails(updatedProduct.data);
    } catch (error) {
      console.error("Error updating product:", error);
      setMessage(error.response?.data?.error || "Failed to update product.");
    }
  };

  return (
    <div className="update-product-wrapper">
      <h1>Update Products</h1>

      <form onSubmit={handleSearchSubmit} className="update-product-search">
        <label>Search for Product:</label>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by product name"
        />
        <button type="submit">Search</button>
      </form>

      <div className="update-product-results">
        {searchResults.map((product) => (
          <div
            key={product._id}
            onClick={() => selectProduct(product._id)}
            className="update-product-item"
          >
            {product.name}
          </div>
        ))}
      </div>

      {selectedProduct && (
        <form onSubmit={handleUpdateSubmit} className="update-product-form">
          <h2>Update {selectedProduct.name}</h2>

          <label>Product Name:</label>
          <input
            type="text"
            name="name"
            value={productDetails.name || ""}
            onChange={handleInputChange}
          />

          <label>Description:</label>
          <textarea
            name="description"
            value={productDetails.description || ""}
            onChange={handleInputChange}
          />

          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={productDetails.price || ""}
            onChange={handleInputChange}
          />

          <label>Category:</label>
          <input
            type="text"
            name="category"
            value={productDetails.category || ""}
            onChange={handleInputChange}
          />

          <label>Subcategory:</label>
          <input
            type="text"
            name="subcategory"
            value={productDetails.subcategory || ""}
            onChange={handleInputChange}
          />

          <label>Sub-Subcategory:</label>
          <input
            type="text"
            name="subsubcategory"
            value={productDetails.subsubcategory || ""}
            onChange={handleInputChange}
          />

          <label>Image URLs:</label>
          <input
            type="text"
            name="imageUrls"
            value={productDetails.imageUrls?.join(", ") || ""}
            onChange={(e) =>
              setProductDetails((prevDetails) => ({
                ...prevDetails,
                imageUrls: e.target.value.split(",").map((url) => url.trim()),
              }))
            }
          />

          <label>Change Image:</label>
          {productDetails.imageUrls && productDetails.imageUrls[0] && (
            <img
              src={productDetails.imageUrls[0]}
              alt="Current Product"
              className="product-image"
            />
          )}
          <input type="file" onChange={handleImageChange} />

          <label>Popular Product:</label>
          <input
            type="checkbox"
            name="isPopular"
            checked={productDetails.isPopular || false}
            onChange={handleInputChange}
          />

          <button type="submit">Update Product</button>
        </form>
      )}
      {message && <p className="update-product-message">{message}</p>}
    </div>
  );
};

export default UpdateProductPage;